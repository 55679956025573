import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'

export type TOrganisation = {
  allowAon: boolean
  assessmentsSource: TAssessmentsSource
  billingData: TBillingData
  created: string
  createdBy: string
  id: string
  name: string
  status: 'ACTIVE' | string
  source: keyof typeof ORGANISATION_SOURCE
  updated: string
  updatedBy: string
  timezone: string
  products: Array<string>
}

export interface RTOrganisationInfo extends RTAxiosDefault {
  data?: TOrganisation
}

export enum ORGANISATION_PRODUCTS {
  HIRING = 'HIRING',
  INTERNAL_ASSESSMENT = 'INTERNAL_ASSESSMENT',
  SKILL = 'SKILL',
}

export const ASSESSMENTS_SOURCE = {
  AON: 'AON',
  AON_THRIVE: 'AON_THRIVE',
  THRIVE: 'THRIVE',
} as const

export type TAssessmentsSource = keyof typeof ASSESSMENTS_SOURCE

export const ORGANISATION_SOURCE = {
  SIGN_UP: 'SIGN_UP',
  ADMIN: 'ADMIN',
} as const

type TBillingData = {
  billingStatus: TBillingStatus
  currency: TCurrency
  email: string
  stripeId: string
  remainingFreeTrialCredits: {
    amount: number
    expirationDate: string
  }
  // Amount assigned by subscription package
  subscriptionCredits: number
  // For yearly subscription
  usedCredits: number
  yearlySubscriptionPlan: 'NO YEARLY PLAN' | string
}

export const BILLING_STATUS = {
  PLAN: 'PLAN',
  TRIAL: 'TRIAL',
  PAYG: 'PAYG',
  PENDING_TRIAL: 'PENDING_TRIAL',
}

export type TBillingStatus = keyof typeof BILLING_STATUS

export type TGetCustomerPortalUrl = {
  organisationId: string
  token: string
}

export type TGetCustomerPortalUrlData = {
  customerPortalUrl: string
}

export type RTGetCustomerPortalUrlData = {
  status: number
  data: TGetCustomerPortalUrlData
}

export const CURRENCY = {
  ZAR: 'ZAR',
  NZD: 'NZD',
  AUD: 'AUD',
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
} as const

export type TCurrency = keyof typeof CURRENCY

export type TAvailableProduct = {
  creditsAmount: number
  currencyType: TCurrency
  description: string
  id: string
  monthlyPrice: number
  monthlyPriceId: string
  name: string
  priceId: string
  tier: number
  yearlyPrice: number
  yearlyPriceId: string
}

export const STRIPE_PRICE_TYPES = {
  monthlyPrice: 'monthlyPrice',
  yearlyPrice: 'yearlyPrice',
} as const

export type TStripePriceType = keyof typeof STRIPE_PRICE_TYPES

export const PRICE_FREQUENCY = {
  monthly: 'monthly',
  yearly: 'yearly',
} as const

export type TPriceFrequency = keyof typeof PRICE_FREQUENCY

// Map of Stripe prices to displayed prices according to frequency
export const PRICE_TYPE_MAP: Record<TStripePriceType, TPriceFrequency> = {
  [STRIPE_PRICE_TYPES.monthlyPrice]: PRICE_FREQUENCY.monthly,
  [STRIPE_PRICE_TYPES.yearlyPrice]: PRICE_FREQUENCY.yearly,
} as const

export type TTransformedAvailableProduct = TAvailableProduct & {
  priceFrequency: TPriceFrequency
}

export type TProductsByFrequency = Record<
  TPriceFrequency,
  TTransformedAvailableProduct[]
>

export type RTAvailableProducts = RTAxiosDefault & {
  data?: {
    content: TAvailableProduct[]
  }
}

// Checkout types for version A
export type TRequestCheckout = {
  organisationId: TOrganisation['id']
  bearerToken: string
  requestBody: {
    cancelRedirectUrl: string
    priceId: TAvailableProduct['priceId']
    successRedirectUrl: string
  }
}

export type RTRequestCheckout = RTAxiosDefault & {
  data?: {
    customerPortalUrl: string
    sessionCheckoutUrl: string
  }
}

export const CHECKOUT_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  CANCELLED: 'CANCELLED',
} as const

export type TCheckoutStatus = keyof typeof CHECKOUT_STATUS

export type TRequestEndTrial = {
  organisationId: TOrganisation['id']
  bearerToken: string
}

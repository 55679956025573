import { keyframes } from 'styled-components'

export const inAnimation = keyframes`
  0% { 
    transform: translateX(100%);
    }
  100% { 
    transform: translateX(0%);
    }
`
export const outAnimation = keyframes`
  0% { 
    transform: translateX(0%);
    }
  100% { 
    transform: translateX(100%);
    }
`

const CAMPAIGN_CREATION_EVENTS = {
  CAMPAIGN_CREATION_ABANDONED: 'Campaign creation abandoned',
  CAMPAIGN_CREATION_AUTOMATIC_FEEDBACK_OFF:
    'Automatic feedback turned off on campaign creation',
  CAMPAIGN_CREATION_STARTED: 'Campaign creation started',
  CAMPAIGN_DRAFT_SAVED: 'Campaign draft saved',
  CAMPAIGN_PUBLISHED: 'Campaign published',
  VIEW_MORE_TESTS: 'View more test clicked when skills only',
  ASSESSMENT_INFO: 'Assessment info clicked',
  ASSESSMENT_TYPE_TURNED_OFF: 'Assessment type turned off',
  READ_MORE_SKILL: 'Skill read more button clicked',
}

export const EVENT_NAMES = {
  CANDIDATE_DETAILS_REQUESTED: 'Candidate details requested',
  CANDIDATE_INTERVIEW_REQUESTED: 'Candidate interview requested',
  PRODUCT_REQUESTED: 'Product requested',
  REPORT_SHARED: 'Report shared',
  THRIVE_CANDIDATE_BANNER_DISMISSED: 'Thrive candidates banner dismissed',
  THRIVE_CANDIDATE_BANNER_USED: 'Thrive candidates banner used',
  COLLEGE_PACKAGE_PURCHASED: 'College package purchased',
  THRIVE_COLLEGE_VISITED: 'Thrive College visited',
  SIGN_UP: 'New organisation created through sign up',
  ADD_COLLEAGUE: 'Colleagues added through sign up',
  ONBOARDING_STARTED: 'Onboarding started',
  ONBOARDING_FINISHED: 'Onboarding finished',
  ...CAMPAIGN_CREATION_EVENTS,
} as const

export const PRODUCTS = {
  PLATFORM: 'platform',
  THRIVE_CANDIDATES: 'thriveCandidates',
  AVA: 'ava',
}

import { useState } from 'react'
import { TSessionStorageKey } from './keys'
import { is } from 'helpers/utils/is'

type RTUseSessionStorage<TStoredType> = [
  TStoredType,
  (newValue: TStoredType) => void,
  VoidFunction
]

export const useSessionStorage = <TStoredType,>(
  key: TSessionStorageKey,
  initialValue?:
    | Array<any>
    | boolean
    | null
    | number
    | Record<string, unknown>
    | string
    | undefined
): RTUseSessionStorage<TStoredType> => {
  const [value, setStoredValue] = useState<TStoredType>(() => {
    try {
      const item = is.clientSide ? sessionStorage.getItem(key) : undefined
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('useSessionStorage', error)
      return initialValue
    }
  })

  const setValue = (newValue: TStoredType) => {
    try {
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue
      setStoredValue(valueToStore)
      is.clientSide
        ? sessionStorage.setItem(key, JSON.stringify(valueToStore))
        : new Error("Can't save data on server side")
    } catch (error) {
      console.error('useSessionStorage', error)
    }
  }

  const clearValue = () => {
    try {
      setStoredValue(undefined)
      is.clientSide
        ? sessionStorage.removeItem(key)
        : new Error("Can't save data on server side")
    } catch (error) {
      console.error('useSessionStorage', error)
    }
  }

  return [value, setValue, clearValue]
}

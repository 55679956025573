import { DateTime, Zone } from 'luxon'
import { RTDifferenceBetweenDates, RTMsToTime, TDateValues } from './date.types'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const now = (timezone: string | Zone) => {
  const now = DateTime.local()

  return now.setZone(timezone)
}

export const newDateWithZone = (isoDate: string, timezone: string | Zone) =>
  DateTime.fromISO(isoDate, { zone: timezone })

export const newDateWithZoneFromObject = (dateObject, timezone) =>
  DateTime.fromObject({ ...dateObject }, { zone: timezone })

const inMs = {
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 86400000,
}

export const today = (timezone) => now(timezone).startOf('day')

export const tomorrow = (timezone) => now(timezone).plus({ days: 1 })

export const dateToString = (date: DateTime): string => date.toISODate()

export const getDaysOfTheMonth = (date: DateTime): number[] => {
  const lastDay = date.endOf('month').day
  return [...Array(lastDay).keys()].map((day) => day + 1)
}

export const getMonthStart = (date: DateTime): number => {
  return date.set({ day: 1 }).weekday
}

export const firstDateOf = (date: DateTime): DateTime => date.startOf('month')

export const getEmptyDays = (monthStart: number): number[] => {
  return [...Array(monthStart).keys()]
}

const msToTime = (milliseconds: number): RTMsToTime => {
  const days = Math.trunc(milliseconds / inMs.day)

  let hours = Math.floor((milliseconds / inMs.hour) % 24)
  hours = hours < 0 ? 0 : hours

  let minutes = Math.floor((milliseconds / inMs.minute) % 60)
  minutes = minutes < 0 ? 0 : minutes

  let seconds = Math.floor((milliseconds / inMs.second) % 60)
  seconds = seconds < 0 ? 0 : seconds

  return { days, hours, minutes, seconds }
}

export const distanceBetweenDates = (
  date1: DateTime,
  date2: DateTime
): RTDifferenceBetweenDates => {
  const differenceInMs = date2.diff(date1).milliseconds
  const positiveDiff = Math.abs(differenceInMs)
  const differenceInTime = msToTime(positiveDiff)

  const date2IsAfterDate1 = differenceInMs > 0
  const date2IsEqualDate1 =
    date1.day === date2.day && date1.month === date2.month

  return { ...differenceInTime, date2IsAfterDate1, date2IsEqualDate1 }
}

export type TDifferenceUntilDeadline = {
  asString: string
  inDays: number
}

export const calcDifferenceUntilDeadline = (
  deadline: string,
  timezone
): TDifferenceUntilDeadline => {
  const deadlineDate = newDateWithZone(deadline, timezone)

  const differenceUntilDeadline = distanceBetweenDates(
    // firstHoursOf(now(timezone)),
    now(timezone).startOf('day').setZone(timezone),
    deadlineDate
  )
  const differenceInDays = differenceUntilDeadline.days

  if (!differenceUntilDeadline.date2IsAfterDate1)
    return { asString: '0 days', inDays: differenceInDays }

  switch (differenceInDays) {
    case 0:
      return { asString: 'Ends today', inDays: differenceInDays }
    case 1:
      return { asString: '1 day', inDays: differenceInDays }
    default:
      return {
        asString: `${differenceInDays} days`,
        inDays: differenceInDays,
      }
  }
}

export const maxSelectableDateOfBirth = dateToString(
  DateTime.now().minus({ years: 16 })
)

export const isStringDate = (date: Date | DateTime | string): boolean =>
  typeof date === 'string'

export const getDateValues = (date: Date): TDateValues => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return { day, month, year }
}

export const getDateTimeValues = (date: DateTime): TDateValues => {
  const day = date.day
  const month = date.month
  const year = date.year
  return { day, month, year }
}

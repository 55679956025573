import { FC } from 'react'
import { StyledText } from './text.styles'
import { TText } from './text.types'

export const Text: FC<TText> = ({
  as,
  children,
  color = 'black',
  colorSubfolder,
  customStyles,
  htmlFor,
  id,
  size = 2,
  transform,
  weight = 'normal',
}) => (
  <StyledText
    as={as}
    color={color}
    colorSubfolder={colorSubfolder}
    customStyles={customStyles}
    htmlFor={htmlFor}
    id={id}
    size={size}
    transform={transform}
    weight={weight}
  >
    {children}
  </StyledText>
)

import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import { Icon, StyledButton, StyledLink } from './button.styles'
import { VARIANT, TButton, TStatus } from './button.types'

export const Button: FC<TButton> = ({
  customStyles,
  download = false,
  icon,
  iconPosition = 'left',
  link,
  onClick,
  linkClick,
  rel,
  status = {
    disabled: false,
    error: false,
    loading: false,
    normal: true,
    pass: false,
    selected: false,
  },
  target,
  text,
  type = 'button',
  variant = VARIANT.PRIMARY,
  textColor,
  id,
  className,
}) => {
  const [buttonStatus, setButtonStatus] = useState(TStatus.NORMAL)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const getStatus = () => {
      switch (true) {
        case status.disabled:
          return TStatus.DISABLED
        case status.error:
          return TStatus.ERROR
        case status.loading:
          return TStatus.LOADING
        case status.pass:
          return TStatus.PASS
        case status.selected:
          return TStatus.SELECTED
        default:
          return TStatus.NORMAL
      }
    }
    const isButtonDisabled = status.disabled || status.loading

    setButtonStatus(getStatus())
    setIsDisabled(isButtonDisabled)
  }, [status])

  if (link && !isDisabled) {
    return (
      <Link as={link.as} href={isDisabled ? '' : link.href} passHref>
        <StyledLink
          className={className}
          customStyles={customStyles}
          download={download}
          iconPosition={iconPosition}
          rel={rel}
          status={buttonStatus}
          target={target}
          variant={variant}
          textColor={textColor}
          onClick={(event) => (linkClick ? linkClick(event) : null)}
          id={id}
        >
          {icon && <Icon src={icon.src} alt={icon.alt} />}
          {typeof text === 'object' && text[buttonStatus]
            ? text[buttonStatus]
            : text}
        </StyledLink>
      </Link>
    )
  } else {
    return (
      <StyledButton
        className={className}
        customStyles={customStyles}
        disabled={isDisabled}
        iconPosition={iconPosition}
        onClick={(event) => onClick(event)}
        status={buttonStatus}
        type={type}
        variant={variant}
        textColor={textColor}
        id={id}
      >
        {icon && <Icon src={icon.src} alt={icon.alt} />}
        {typeof text === 'object' && text[buttonStatus]
          ? text[buttonStatus]
          : text}
      </StyledButton>
    )
  }
}

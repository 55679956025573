import styled, { css, CSSProp } from 'styled-components'
import { flex } from '~functions/flex'
import { size } from '~functions/size'
import { TButton, TStatus, TVariant, VARIANT } from './button.types'

// TODO: update styles for different states to match new designs
const buttonCommonStyles = css`
  border-radius: ${({ theme }) => theme.extra.borderRadius.m};
  padding: 12px ${({ theme }) => theme.spacing.xl};

  &:hover {
    box-shadow: 0px 2px 4px 0px rgba(44, 63, 88, 0.40);
  }
  &:active {
    box-shadow: 0px 2px 4px 0px #2C3F58;
  }
`

const PrimaryVariant = css`
  ${buttonCommonStyles}
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
`

// TODO: remove this variant and replace tertiary with new one
const SecondaryVariant = css`
  ${buttonCommonStyles}
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
`

const TertiaryVariant = css`
  ${buttonCommonStyles}
  background-color: transparent;
  color: ${({ theme }) => theme.color.black};
  padding: 12px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(44, 63, 88, 0.12);
    box-shadow: none;
  }
`

const QuaternaryVariant = css`
  ${buttonCommonStyles}
  background-color: ${({ theme }) => theme.color.disabled};
  color: ${({ theme }) => theme.color.black};
`

const QuinaryVariant = css`
  ${buttonCommonStyles}
  background-color: transparent;
  color: ${({ theme }) => theme.color.black};
  padding: 12px;
  border: 2px solid ${({theme}) => theme.color.black};
`

const styledVariant = (variant: TVariant) => {
  switch (variant) {
    case VARIANT.PRIMARY:
      return PrimaryVariant
    case VARIANT.SECONDARY:
      return SecondaryVariant
    case VARIANT.TERTIARY:
      return TertiaryVariant
    case VARIANT.QUATERNARY:
      return QuaternaryVariant
    case VARIANT.QUINARY:
      return QuinaryVariant
    default:
      return PrimaryVariant
  }
}

const DisabledStatus = css`
  opacity: 24%;
  cursor: not-allowed;
`

const PassStatus = css`
  background-color: ${({ theme }) => theme.color.pass};
  cursor: default;
  color: ${({ theme }) => theme.color.white};
  opacity: 100%;
`

const SelectedStatus = css`
  background-color: ${({ theme }) => theme.color.popBlue};
`

const ErrorStatus = css`
  background-color: ${({ theme }) => theme.color.error};
  color: ${({ theme }) => theme.color.white};
`

const LoadingStatus = css`
  ${DisabledStatus}
`

const styledStatus = (status: TStatus) => {
  switch (status) {
    case TStatus.DISABLED:
      return DisabledStatus
    case TStatus.ERROR:
      return ErrorStatus
    case TStatus.PASS:
      return PassStatus
    case TStatus.LOADING:
      return LoadingStatus
    case TStatus.SELECTED:
      return SelectedStatus
    case TStatus.NORMAL:
    default:
      return ''
  }
}

const StyledAction = css<{
  iconPosition: TButton['iconPosition']
  status: TStatus
  variant: TVariant
}>`
  ${flex()};
  gap: ${({ theme }) => theme.spacing.s};
  border-radius: ${({ theme }) => theme.extra.borderRadius.m};
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: normal;
  padding: 8px 0;
  width: 100%;
  max-width: 360px;
  transition: background-color 850ms linear;

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      ${Icon} {
        order: 2;
      }
    `}
  ${({ variant }) => styledVariant(variant)}
  ${({ status }) => styledStatus(status)}
`

export const StyledButton = styled.button<{
  customStyles?: CSSProp
  iconPosition?: TButton['iconPosition']
  status: TStatus
  variant: TVariant
  textColor?: TButton['textColor']
}>`
  ${StyledAction}
  ${({ customStyles }) => customStyles || ''};
  ${({ textColor, theme }) =>
    textColor &&
    css`
      color: ${theme.color[textColor] || theme.color.fitIA[textColor]};

      &:hover {
        color: ${theme.color[textColor] || theme.color.fitIA[textColor]};
      }
    `}
`

export const StyledLink = styled.a<{
  customStyles?: CSSProp
  iconPosition?: TButton['iconPosition']
  status: TStatus
  variant: TVariant
  textColor?: TButton['textColor']
}>`
  ${StyledAction};
  ${({ customStyles }) => customStyles || ''}
  ${({ textColor, theme }) =>
    textColor &&
    css`
      color: ${theme.color[textColor] || theme.color.fitIA[textColor]};

      &:hover {
        color: ${theme.color[textColor] || theme.color.fitIA[textColor]};
      }
    `}
`

export const Icon = styled.img`
  ${size('16px', 'auto')};
`

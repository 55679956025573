import { FC } from 'react'
import { useRouter } from 'next/router'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import { useAuth } from '../useAuth/useAuth'
import { onError } from '@apollo/client/link/error'
import { useLocalStorage } from '../useLocalStorage/useLocalStorage'
import { EMPLOYEE_TOKEN, CANDIDATE_TOKEN } from '../useLocalStorage/keys'
import {
  EMPLOYEE_JOURNEY_ROUTES,
  CANDIDATE_JOURNEY_ROUTES,
  ROUTES_ALLOWED_REDIRECTION
} from 'helpers/utils/routes'

export const CustomApolloProvider: FC = ({ children }) => {
  const router = useRouter()

  const { logOut, refreshToken, updateTokenWithRefreshToken, token } = useAuth()
  const [employeeToken, , clearEmployeeToken] = useLocalStorage(EMPLOYEE_TOKEN)
  const [candidateToken, , clearCandidateToken] =
    useLocalStorage(CANDIDATE_TOKEN)


  const availableToken = () => {
    if (EMPLOYEE_JOURNEY_ROUTES.includes(router.pathname)) return employeeToken
    if (CANDIDATE_JOURNEY_ROUTES.includes(router.pathname))
      return candidateToken

    return token
  }

  const httpLink = createHttpLink({
    uri: `${process.env.NEXT_PUBLIC_API_URL}${ENDPOINTS.GRAPHQL}`,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? availableToken() : '',
      },
    }
  })

  const errorLink = onError(
    ({ networkError, operation, forward }) => {
      if (networkError?.message?.slice(-3) === '401') {
        if(router.route !== '/login') {
          if (
            refreshToken &&
            !ROUTES_ALLOWED_REDIRECTION.includes(router.pathname)
          ) {
            updateTokenWithRefreshToken()
          } else {
            clearCandidateToken()
            clearEmployeeToken()
            logOut()
          }
        }
        return forward(operation)
      }
    }
  )

  const queryLink = authLink.concat(httpLink)

  const client = new ApolloClient({
    link: from([errorLink, queryLink]),
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

import { Button } from '@atoms/button/button'
import { VARIANT } from '@atoms/button/button.types'
import { StepType, TourProps } from '@reactour/tour'
import { theme } from '~theme/index'
import { customStyledButton } from './tour.styles'

export const reactTourStyles: TourProps['styles'] = {
  maskArea: (base) => ({
    ...base,
    rx: 10,
  }),
  popover: (base) => ({
    ...base,
    backgroundColor: theme.color.whiteDark,
    borderRadius: theme.extra.borderRadius.l,
    color: theme.color.blueDark,
    fontFamily: theme.font.family.primary,
    fontWeight: 500,
    fontSize: theme.font.size.s,
    lineHeight: '20px',
    minWidth: 'auto',
    maxWidth: 390,
    '--reactour-accent': theme.color.blackDark,
  }),
  badge: (base) => ({
    ...base,
    color: theme.color.white,
    backgroundColor: theme.color.popGreen,
    fontSize: theme.font.size.l,
  }),
  dot: (base) => ({
    ...base,
    order: -1,
  }),
}

export const BackButton = ({ currentStep, setCurrentStep }) => {
  const first = currentStep === 0
  if (first) return () => null
  return (
    <Button
      customStyles={customStyledButton}
      onClick={() => setCurrentStep((s: number) => s - 1)}
      variant={VARIANT.TERTIARY}
      text="Back"
    />
  )
}

export const NextButton = ({
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps,
}) => {
  const last = currentStep === stepsLength - 1
  return (
    <Button
      customStyles={customStyledButton}
      onClick={() => {
        if (last) {
          setIsOpen(false)
        } else {
          setCurrentStep((s: number) => (s === steps?.length - 1 ? 0 : s + 1))
        }
      }}
      text="Continue"
    />
  )
}

// Helper to find a specific selector from tour steps
export const findFirstPopOverOfThePage = (
  steps: StepType[],
  searchedSelector: string
) => steps.findIndex(({ selector }) => selector === searchedSelector)

import { RTMe } from 'api/user.types'
import { TEventNames } from './analytics.types'
import { TOrganisation } from 'api/organisation.types'

export const segmentIdentification = (
  employerData: RTMe,
  organisationData: TOrganisation
) => {
  global.analytics.identify(
    employerData?.id,
    {
      id: employerData?.id,
      lastName: employerData?.lastName,
      organisationId: employerData?.organisationId,
      role: employerData?.role,
      updated: employerData?.updated,
      company: {
        name: organisationData?.name,
        id: organisationData?.id,
      },
    },
    {
      integrations: {
        All: false,
        Intercom: true,
      },
    }
  )
}

export const handleTracking = (
  eventName: TEventNames,
  eventProps: Record<string, any>
): void => {
  global.analytics.track(eventName, eventProps)
}

import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { createPortal } from 'react-dom'

import { useId } from 'helpers/hooks/useId/useId'
import { ToastNotification } from '@molecules/toastNotification/toastNotification'
import { ToastPortalContainer } from './toastPortal.styles'
import { TToastNotification } from '@molecules/toastNotification/toastNotification.types'

export const ToastPortal = forwardRef((props, ref) => {
  const [portalNode, setPortalNode] = useState(null)
  const [notifications, setNotifications] = useState<TToastNotification[]>([])

  useEffect(() => {
    setPortalNode(document.getElementsByTagName('body')[0])
  }, [])

  useImperativeHandle(ref, () => ({
    addToast(newNotification: TToastNotification) {
      setNotifications([...notifications, { ...newNotification, id: useId() }])
    },
  }))

  const removeToast = (id: TToastNotification['id']) => {
    setNotifications(notifications.filter((n) => n.id !== id))
  }

  return portalNode ? (
    createPortal(
      <ToastPortalContainer>
        {notifications.map((n) => (
          <ToastNotification
            key={n.id}
            message={n.message}
            type={n.type}
            onClose={() => removeToast(n.id)}
          />
        ))}
      </ToastPortalContainer>,
      portalNode
    )
  ) : (
    <> </>
  )
})

import { axios } from 'helpers/hooks/useAxios/axios'
import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import {
  RTEmployeeProfileInfo,
  TEmployeeProfile,
  TEmployeeProfileUpdateInfo,
} from './employeeProfile.types'

const updateInfo = async ({
  profileId,
  updatedProfileInfo,
  bearerToken,
}: TEmployeeProfileUpdateInfo): Promise<RTAxiosDefault> => {
  try {
    const { status } = await axios.put(
      ENDPOINTS.EMPLOYEE_PROFILES.BY_ID(profileId),
      updatedProfileInfo,
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const info = async (
  profileId: TEmployeeProfile['id'],
  bearerToken: string
): Promise<RTEmployeeProfileInfo> => {
  try {
    const { status, data } = await axios.get(
      ENDPOINTS.EMPLOYEE_PROFILES.BY_ID(profileId),
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

export const employee_profile_api = {
  info,
  updateInfo,
}

import { StyledWarning } from './warning.styles'

export const Warning = ({ color = 'white' }) => (
  <StyledWarning
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.07801 0.664195L0.167005 16.0894C-0.285604 16.8729 0.23503 17.8891 1.08901 17.8891H18.911C19.765 17.8891 20.2856 16.8729 19.833 16.0894L10.922 0.664195C10.4961 -0.0729615 9.50386 -0.0729603 9.07801 0.664195ZM8.88889 6.778C8.88889 6.16435 9.38634 5.66688 10 5.66688C10.6137 5.66688 11.1111 6.16435 11.1111 6.778V11.2225C11.1111 11.8361 10.6137 12.3336 10 12.3336C9.38634 12.3336 8.88889 11.8361 8.88889 11.2225V6.778ZM8.88889 14.5558C8.88889 13.9421 9.38634 13.4447 10 13.4447C10.6137 13.4447 11.1111 13.9421 11.1111 14.5558C11.1111 15.1694 10.6137 15.6669 10 15.6669C9.38634 15.6669 8.88889 15.1694 8.88889 14.5558Z"
      fill={color}
    />
  </StyledWarning>
)

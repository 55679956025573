import styled from 'styled-components'
import { flex } from '~functions/flex'
import { TToastNotificationContainer } from './toastNotification.types'
import { inAnimation, outAnimation } from '~animations/inAndOut'
import { size } from '~functions/size'

export const ToastNotificationContainer = styled.div<{
  type: TToastNotificationContainer['type']
  animateOut: TToastNotificationContainer['animateOut']
}>`
  ${flex('row', 'center', 'space-between')};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border: ${({ type, theme }) => theme.color[type]};
  border-radius: ${({ theme }) => theme.extra.borderRadius.m};
  margin-top: ${({ theme }) => theme.spacing.s};
  gap: ${({ theme }) => theme.spacing.s};
  padding: ${({ theme }) => `12px ${theme.spacing.m}`};
  box-shadow: ${({ theme }) => theme.extra.boxShadow.xs};
  animation-name: ${({ animateOut }) =>
    animateOut ? outAnimation : inAnimation};
  animation-duration: 2s;
  width: 100%;
  max-width: 550px;
`
export const ToastIconContainer = styled.div<{
  type: TToastNotificationContainer['type']
}>`
  ${size('36px')};
  ${flex()};
  min-width: 36px;
  border-radius: 50%;
  background-color: ${({ type, theme }) => theme.color[type]};
`

export const CloseIconContainer = styled.div`
  cursor: pointer;
  svg {
    transform: scale(0.8);
  }
`

export const Icon = styled.img`
  transform: scale(1.6);
`

type RTIs = {
  clientSide: boolean
  serverSide: boolean
  mobileBrowser: () => RegExpMatchArray
  safariBrowser: () => boolean
  chromeBrowser: () => boolean
}

export const is: RTIs = {
  clientSide: typeof window !== 'undefined',
  serverSide: typeof window === 'undefined',
  mobileBrowser: function () {
    return (
      this.clientSide &&
      (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i))
    )
  },
  safariBrowser: function () {
    return (
      this.clientSide &&
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= -1
    )
  },
  chromeBrowser: function () {
    return (
      this.clientSide &&
      navigator.userAgent.indexOf('Chrome') > -1
    )
  }
}

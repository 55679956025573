export const extra = {
  borderRadius: {
    xxs: '2px',
    xs: '4px',
    s: '6px',
    m: '8px',
    l: '12px',
    xl: '16px',
    xxl: '18px',
    xxxl: '24px',
    xxxxl: '32px'
  },
  boxShadow: {
    xs: '-2px 2px 5px 0 rgba(67, 86, 100, 0.06), -1px 1px 2px 0 rgba(67, 70, 74, 0)',
  },
}

import { createGlobalStyle } from 'styled-components'
import { font } from '~theme/font'

export const GlobalStyle = createGlobalStyle`
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: ${font.family.primary}, sans-serif;
    overscroll-behavior: none;
  }

  *,
  *:before,
  *:after {
	  box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    display: block;
    font-family: ${font.family.primary}, sans-serif;
    text-decoration: none;
    text-align: center;
  }

  a,
  button {
    cursor: pointer;
  }

  input {
    padding: 0;
  }

  span,
  button,
  input,
  label,
  li,
  p,
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-family: ${font.family.primary}, sans-serif;
  }

  p,
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    user-select: none;
  }

  pre,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button {
    margin: 0;
    padding: 0;
  }

  ul, li {
    list-style: none;
  }

  button {
    background: transparent;
    border: 0;
    outline: 0;
  }

  #__next {
    background-image: url('/images/jpg/components/background_pattern.jpg');
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100vh;
  }


  @media print {
    @page {
      size: A4;
      margin: 0mm !important;
    }

    #__next {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important; 
      background-image: unset !important;
      background-color: white !important;
    }
  }
`

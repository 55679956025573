import { breakpoint } from './breakpoint'
import { color } from './color'
import { DefaultTheme } from 'styled-components'
import { extra } from './extra'
import { font } from './font'
import { spacing } from './spacing'

export const theme: DefaultTheme = {
  breakpoint: { ...breakpoint },
  color: { ...color },
  extra: { ...extra },
  font: { ...font },
  spacing: { ...spacing },
}

import styled, { CSSProp } from 'styled-components'
import { TText } from './text.types'

const SIZES = {
  0: 'xs',
  1: 's',
  2: 'm',
  3: 'l',
  4: 'xl',
  5: 'xxl',
  6: 'xxxl',
}

export const StyledText = styled.p<{
  color: TText['color']
  customStyles?: CSSProp
  size: TText['size']
  weight: TText['weight']
  colorSubfolder?: string
  transform?: string
}>`
  color: ${({ theme, color, colorSubfolder }) =>
    colorSubfolder
      ? theme.color[colorSubfolder][color]
      : theme.color[color] ||
        theme.color['fit'][color] ||
        theme.color['fitIA'][color] ||
        theme.color['alignment'][color]};
  font-size: ${({ theme, size }) => theme.font.size[SIZES[size]]};
  font-weight: ${({ weight }) => (weight === 'bold' ? 'bold' : 'normal')};
  ${({ transform }) => transform && `text-transform: ${transform}`};

  ${({ customStyles }) => customStyles || ''};
`

import { LinkProps } from 'next/link'
import { ButtonHTMLAttributes, MouseEventHandler, ReactChild } from 'react'
import { CSSProp } from 'styled-components'
import { DefaultTheme } from 'styled-components'

export const VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  QUINARY: 'quinary',
}

export type TVariant =
  | typeof VARIANT.PRIMARY
  | typeof VARIANT.SECONDARY
  | typeof VARIANT.TERTIARY
  | typeof VARIANT.QUATERNARY
  | typeof VARIANT.QUINARY

export const REL = {
  ALTERNATE: 'alternate',
  AUTHOR: 'author',
  EXTERNAL: 'external',
  HELP: 'help',
  LICENSE: 'license',
  NEXT: 'next',
  NOFOLLOW: 'nofollow',
  PREV: 'prev',
  SEARCH: 'search',
  TAG: 'tag',
}

type TRel =
  | typeof REL.ALTERNATE
  | typeof REL.AUTHOR
  | typeof REL.EXTERNAL
  | typeof REL.HELP
  | typeof REL.LICENSE
  | typeof REL.NEXT
  | typeof REL.NOFOLLOW
  | typeof REL.PREV
  | typeof REL.SEARCH
  | typeof REL.TAG

export const TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
}

export enum TStatus {
  DISABLED = 'disabled',
  ERROR = 'error',
  LOADING = 'loading',
  NORMAL = 'normal',
  PASS = 'pass',
  SELECTED = 'selected',
}

export interface TButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  customStyles?: CSSProp
  download?: boolean
  icon?: { alt: string; src: string }
  iconPosition?: 'left' | 'right'
  link?: LinkProps
  rel?: TRel
  status?: {
    [key in TStatus]?: boolean
  }
  target?: '_blank' | '_self' | '_parent' | '_top'
  text?:
    | Element
    | ReactChild
    | string
    | {
        [key in TStatus]?: string
      }
  variant?: TVariant
  textColor?: keyof DefaultTheme['color'] | keyof DefaultTheme['color']['fitIA']
  linkClick?: MouseEventHandler<HTMLAnchorElement>
  id?: string
}

export enum spacing {
  zero = '0',
  xxs = '2px',
  xs = '4px',
  s = '8px',
  m = '16px',
  l = '24px',
  xl = '32px',
  xxl = '48px',
  xxxl = '64px',
}

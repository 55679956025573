import { createContext, FC, useContext, useEffect } from 'react'
import {
  STEPPER_CONFIG,
  STEPPER_DATA,
  STEPPER_RELATED_COOKIES,
} from '../useSessionStorage/keys'
import {
  TInitializeConfig,
  TStepperContext,
  TUseStepper,
} from './useStepper.types'
import { useSessionStorage } from '../useSessionStorage/useSessionStorage'
import { useRouter } from 'next/router'
import { useCookies } from '../useCookies/useCookies'

const initialStepperContext: TStepperContext = {
  cleanedData: () => undefined,
  clear: () => undefined,
  data: undefined,
  initialize: (_nextData, _config: TInitializeConfig) => undefined,
  isInitialized: false,
  setData: (_nextData) => undefined,
  setRelatedCookies: () => undefined,
}

const StepperContext = createContext(initialStepperContext)

export const StepperProvider: FC = ({ children }) => {
  const router = useRouter()
  const [data, _setData, clearData] = useSessionStorage<
    TStepperContext['data']
  >(STEPPER_DATA, {})
  const [relatedCookies, _setRelatedCookies, clearRelatedCookies] =
    useSessionStorage<TStepperContext['data']>(STEPPER_RELATED_COOKIES, '')
  const [config, setConfig, clearConfig] = useSessionStorage<{
    isInitialized: boolean
    routes: string[]
  }>(STEPPER_CONFIG, {
    isInitialized: false,
    routes: [],
  })

  const clear = () => {
    clearConfig()
    clearData()
    clearRelatedCookies()
  }

  const initialize = (
    nextData: TStepperContext['data'],
    config: TInitializeConfig
  ) => {
    setData(nextData)
    setConfig({ isInitialized: true, routes: config.routes })
  }

  const setRelatedCookies = (cookieKey) => _setRelatedCookies(cookieKey)

  const setData: TStepperContext['setData'] = (nextData) => {
    _setData({
      ...data,
      ...nextData,
    })
  }

  const cleanedData = () => {
    const dataKeys = data
      ? Object.keys(data).filter((key) => !key.startsWith('_'))
      : []

    return dataKeys
      .map((key) => ({
        [key]: data[key],
      }))
      .reduce((acc, curr) => {
        return { ...acc, ...curr }
      }, {})
  }

  useEffect(() => {
    const handleRouteChange = (nextUrl: string) => {
      const isComingOut = !config?.routes.some((route) =>
        nextUrl.includes(route)
      )

      if (config?.isInitialized && isComingOut) {
        if (relatedCookies) {
          useCookies.deleteClientSideCookie(relatedCookies)
        }

        clear()
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [config?.routes])

  return (
    <StepperContext.Provider
      value={{
        cleanedData,
        clear,
        data,
        initialize,
        isInitialized: config?.isInitialized,
        setData,
        setRelatedCookies,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}

export const useStepper: TUseStepper = (): any => {
  const {
    cleanedData,
    clear,
    data,
    initialize,
    isInitialized,
    setData,
    setRelatedCookies,
  } = useContext(StepperContext)
  return {
    cleanedData,
    clear,
    data,
    initialize,
    isInitialized,
    setData,
    setRelatedCookies,
  }
}

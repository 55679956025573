import { useState } from 'react'
import { TLocalStorageKey } from './keys'
import { is } from 'helpers/utils/is'

type RTUseLocalStorage = [any, (newValue: any) => void, () => void]

export const useLocalStorage = (
  key: TLocalStorageKey,
  initialValue?:
    | undefined
    | null
    | string
    | number
    | Record<string, unknown>
    | Array<any>
): RTUseLocalStorage => {
  const [value, setStoredValue] = useState(() => {
    try {
      const item = is.clientSide ? localStorage.getItem(key) : undefined
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('useLocalStorage', error)
      return initialValue
    }
  })

  const setValue = (newValue: any) => {
    try {
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue
      setStoredValue(valueToStore)
      is.clientSide
        ? localStorage.setItem(key, JSON.stringify(valueToStore))
        : new Error("Can't save data on server side")
    } catch (error) {
      console.error('useLocalStorage', error)
    }
  }

  const clearValue = () => {
    try {
      setStoredValue(undefined)
      is.clientSide
        ? localStorage.removeItem(key)
        : new Error("Can't save data on server side")
    } catch (error) {
      console.error('useLocalStorage', error)
    }
  }

  return [value, setValue, clearValue]
}

export const IMPERATIVE_value = (key: TLocalStorageKey): RTUseLocalStorage =>
  JSON.parse(localStorage.getItem(key))

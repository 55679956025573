import { is } from 'helpers/utils/is'
import { TCookiesUtil } from './useCookies.types'

const parseCookies: TCookiesUtil['parseCookies'] = (cookies) => {
  if (cookies) {
    const parsedCookies = cookies
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
        return acc
      }, {})

    return parsedCookies
  }
  return null
}

const deleteServerSideCookie: TCookiesUtil['deleteServerSideCookie'] = (
  res,
  cookieKey,
  path = '/'
) => {
  res.setHeader('Set-Cookie', `${cookieKey}=deleted; path=${path}; Max-Age=-1`)
}

const setServerSideCookie: TCookiesUtil['setServerSideCookie'] = (
  res,
  cookieKey,
  cookieValue,
  path = '/',
  options = ''
) => {
  res.setHeader(
    'Set-Cookie',
    `${cookieKey}=${cookieValue}; path=${path}; ${options}`
  )
}

const getServerSideCookieValue: TCookiesUtil['getServerSideCookie'] = (
  req,
  cookieKey
) => {
  const cookies = parseCookies(req.headers.cookie)

  if (cookies) {
    return cookies[cookieKey]
  }
  return null
}

const deleteClientSideCookie: TCookiesUtil['deleteClientSideCookie'] = (
  cookieKey
) => (document.cookie = `${cookieKey}=deleted; Max-Age=-1`)

const setClientSideCookie: TCookiesUtil['setClientSideCookie'] = (
  cookieKey,
  cookieValue,
  path = '/',
  options = ''
) => {
  document.cookie = `${cookieKey}=${cookieValue}; path=${path}; ${options}`
}

const getClientSideCookie: TCookiesUtil['getClientSideCookie'] = (
  cookieKey
) => {
  if(is.serverSide) return null

  const cookies = parseCookies(document.cookie)
  return cookies && cookies[cookieKey] ? cookies[cookieKey] : null
}

export const useCookies = {
  setServerSideCookie,
  getServerSideCookieValue,
  deleteServerSideCookie,
  deleteClientSideCookie,
  setClientSideCookie,
  getClientSideCookie,
}

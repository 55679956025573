import { TAuthType } from 'helpers/hooks/useAuth/useAuth.types'

export const ROUTE = {
  AON_CALLBACK: '/aon-callback',
  CAMPAIGN_RESULTS: '/campaigns/[campaignId]',
  CAMPAIGNS_CREATE_PSYCHOMETRICS_INFO:
    '/campaigns/create/psychometrics?type=INFO',
  CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_ABILITY:
    '/campaigns/create/psychometrics?type=ABILITY',
  CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_PERSONALITY:
    '/campaigns/create/psychometrics?type=PERSONALITY',
  CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_SKILL:
    '/campaigns/create/psychometrics?type=SKILL',
  CAMPAIGNS_CREATE_PSYCHOMETRICS: '/campaigns/create/psychometrics',
  CAMPAIGNS_CREATE_CANDIDATES: '/campaigns/create/candidates',
  CAMPAIGNS_CREATE_POLLINATION_CANDIDATES: '/campaigns/create/extra-candidates',
  EMPLOYEE_AON_CALLBACK: '/employee-aon-callback',
  EMPLOYEE_ABOUT: '/employees/[employeeId]/about',
  EMPLOYEE_ASSESSMENTS: '/employees/[employeeId]/assessments',
  EMPLOYEE_DOS_AND_DONTS: '/employees/[employeeId]/dos-and-donts',
  EMPLOYEE_WELCOME: '/employees/[employeeId]',
  PROJECT_RESULTS: '/projects/[projectId]',
  PROJECT_RESULTS_EMPLOYEE_MANAGEMENT:
    '/projects/[projectId]/employee-management',
  PROJECT_RESULTS_TEAM_MANAGEMENT: '/projects/[projectId]/team-management',
  PROJECTS_CREATE_EMPLOYEES_QUANTITY: '/projects/create/employees-quantity',
  PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_INFO:
    '/projects/create/multiple-jobs/assessments?view=INFO',
  PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_SELECTION_BEHAVIOURS:
    '/projects/create/multiple-jobs/assessments?view=BEHAVIOURS',
  PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_SELECTION_SURVEYS:
    '/projects/create/multiple-jobs/assessments?view=SURVEYS',
  PROJECTS_CREATE_MULTIPLE_EMPLOYEES:
    '/projects/create/multiple-jobs/employees',
  PROJECTS_CREATE_MULTIPLE_NAME: '/projects/create/multiple-jobs/name',
  PROJECTS_CREATE_MULTIPLE_TEAMS: '/projects/create/multiple-jobs/teams',
  PROJECTS_CREATE_MULTIPLE_SCHEDULING:
    '/projects/create/multiple-jobs/scheduling',
  PROJECTS_CREATE_MULTIPLE_REVIEW: '/projects/create/multiple-jobs/review',
  PROJECTS: '/projects',
  PUBLIC_CANDIDATE_REPORT: '/share/pc/[candidateId]',
  CAMPAIGNS_CREATE_NAME: '/campaigns/create/name',
  CAMPAIGNS_CREATE_REVIEW: '/campaigns/create/review',
  CAMPAIGNS_CREATE_ROLE: '/campaigns/create/role',
  CAMPAIGNS_CREATE_SCHEDULING: '/campaigns/create/scheduling',
  CAMPAIGNS: '/campaigns',
  CANDIDATE_ABOUT: '/candidates/[candidateId]/about',
  CANDIDATE_INTERVIEW_OPPORTUNITIES:
    '/candidates/[candidateId]/interview-opportunities',
  CANDIDATE_ASSESSMENTS: '/candidates/[candidateId]/assessments',
  CANDIDATE_DETAILS: '/campaigns/[campaignId]/candidate/[candidateId]',
  CANDIDATE_DOS_AND_DONTS: '/candidates/[candidateId]/dos-and-donts',
  CANDIDATE_PRIVATE_REPORT: '/candidates/[candidateId]/report',
  CANDIDATE_WELCOME: '/candidates/[candidateId]',
  COLLEGE: '/thrive-college',
  HOME: '/home',
  LOGIN: '/login',
  PASSWORD_REQUEST_RESET: '/password/request-reset',
  PASSWORD_RESET_SENT: '/password/reset-sent',
  PASSWORD_RESET: '/password/reset',
  PASSWORD_SUCCESSFUL_RESET: '/password/successful-reset',
  TERMS: '/terms',
  SIGNUP: '/signup',
  SHORTLIST: '/shortlists',
  SHORTLIST_SEARCH: '/shortlists/search',
  SHORTLIST_RESULTS: '/shortlists/[shortlistId]',
  ONBOARDING: '/onboarding',
}

export const routeType = (route: string): TAuthType => {
  const isEmployerRoute =
    route.startsWith(ROUTE.CAMPAIGNS) || route === ROUTE.HOME
  const isCandidateRoute =
    route.startsWith('/candidates') || route === ROUTE.AON_CALLBACK

  switch (true) {
    case isEmployerRoute:
      return TAuthType.EMPLOYER
    case isCandidateRoute:
      return TAuthType.CANDIDATE
    default:
      return TAuthType.PUBLIC
  }
}

export const ROUTES_ALLOWED_REDIRECTION = [
  ROUTE.CANDIDATE_WELCOME,
  ROUTE.CANDIDATE_ABOUT,
  ROUTE.CANDIDATE_INTERVIEW_OPPORTUNITIES,
  ROUTE.CANDIDATE_DOS_AND_DONTS,
  ROUTE.CANDIDATE_ASSESSMENTS,
  ROUTE.AON_CALLBACK,
  ROUTE.EMPLOYEE_ABOUT,
  ROUTE.EMPLOYEE_ASSESSMENTS,
  ROUTE.EMPLOYEE_DOS_AND_DONTS,
  ROUTE.EMPLOYEE_WELCOME,
  ROUTE.EMPLOYEE_AON_CALLBACK,
]

export const PUBLIC_ROUTES = [
  ROUTE.LOGIN,
  ROUTE.PASSWORD_REQUEST_RESET,
  ROUTE.PASSWORD_RESET_SENT,
  ROUTE.PASSWORD_RESET,
  ROUTE.PASSWORD_SUCCESSFUL_RESET,
  ROUTE.PUBLIC_CANDIDATE_REPORT,
  ROUTE.COLLEGE,
  ROUTE.SIGNUP,
]

export const CREATE_CAMPAIGN_ROUTES = [
  ROUTE.CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_ABILITY,
  ROUTE.CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_PERSONALITY,
  ROUTE.CAMPAIGNS_CREATE_PSYCHOMETRICS_SELECTION_SKILL,
  ROUTE.CAMPAIGNS_CREATE_PSYCHOMETRICS,
  ROUTE.CAMPAIGNS_CREATE_CANDIDATES,
  ROUTE.CAMPAIGNS_CREATE_NAME,
  ROUTE.CAMPAIGNS_CREATE_REVIEW,
  ROUTE.CAMPAIGNS_CREATE_ROLE,
  ROUTE.CAMPAIGNS_CREATE_SCHEDULING,
  ROUTE.CAMPAIGNS_CREATE_POLLINATION_CANDIDATES,
]

export const CANDIDATE_JOURNEY_ROUTES = [
  ROUTE.CANDIDATE_ABOUT,
  ROUTE.CANDIDATE_ASSESSMENTS,
  ROUTE.CANDIDATE_DOS_AND_DONTS,
  ROUTE.CANDIDATE_WELCOME,
  ROUTE.CANDIDATE_INTERVIEW_OPPORTUNITIES,
]

export const CREATE_PROJECT_MULTIPLE_JOBS_ROUTES = [
  ROUTE.PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_INFO,
  ROUTE.PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_SELECTION_BEHAVIOURS,
  ROUTE.PROJECTS_CREATE_MULTIPLE_ASSESSMENTS_SELECTION_SURVEYS,
  ROUTE.PROJECTS_CREATE_MULTIPLE_EMPLOYEES,
  ROUTE.PROJECTS_CREATE_MULTIPLE_NAME,
  ROUTE.PROJECTS_CREATE_MULTIPLE_REVIEW,
  ROUTE.PROJECTS_CREATE_MULTIPLE_SCHEDULING,
]

export const EMPLOYEE_JOURNEY_ROUTES = [
  ROUTE.EMPLOYEE_ABOUT,
  ROUTE.EMPLOYEE_ASSESSMENTS,
  ROUTE.EMPLOYEE_DOS_AND_DONTS,
  ROUTE.EMPLOYEE_WELCOME,
]

export const ROUTES_WITH_STEPPER = [
  ...CANDIDATE_JOURNEY_ROUTES,
  ...CREATE_CAMPAIGN_ROUTES,
  ...CREATE_PROJECT_MULTIPLE_JOBS_ROUTES,
  ...EMPLOYEE_JOURNEY_ROUTES,
]

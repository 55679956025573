import { StepperProvider } from 'helpers/hooks/useStepper/useStepper'
import '../styles/theme/fontFaces.css'
import { useRef } from 'react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '~global'
import { theme } from '~theme/index'
import { AuthProvider } from '../helpers/hooks/useAuth/useAuth'
import '../styles/theme/fontFaces.css'
import { ToastPortal } from '@organisms/toastPortal/toastPortal'
import { TToastPortal } from '@organisms/toastPortal/toastPortal.types'
import { CustomApolloProvider } from 'helpers/hooks/useApolloClient/useApolloClient'
import { TourProvider } from '@reactour/tour'
import { BackButton, NextButton, reactTourStyles } from '@atoms/tour/tour'

const Page: NextPage<AppProps> = ({ Component, pageProps }) => {
  const toastPortalRef = useRef<TToastPortal | null>()

  const addToastNotification = (notification) => {
    toastPortalRef.current.addToast(notification)
  }

  return (
    <AuthProvider>
      <CustomApolloProvider>
        <StepperProvider>
          <ThemeProvider theme={theme}>
            <TourProvider
              disableKeyboardNavigation={['esc']}
              prevButton={BackButton}
              nextButton={NextButton}
              showCloseButton={false}
              steps={pageProps?.tourProps?.steps ?? []}
              styles={reactTourStyles}
              {...pageProps?.tourProps}
            >
              <GlobalStyle />
              <Component
                {...pageProps}
                addToastNotification={addToastNotification}
              />
              <ToastPortal ref={toastPortalRef} />
            </TourProvider>
          </ThemeProvider>
        </StepperProvider>
      </CustomApolloProvider>
    </AuthProvider>
  )
}

export default Page

import _axios, { AxiosInstance } from 'axios'
import { IMPERATIVE_value } from 'helpers/hooks/useLocalStorage/useLocalStorage'
import { is } from 'helpers/utils/is'
import { TOKEN } from '../useLocalStorage/keys'

const IMPERATIVE_bearerToken = is.clientSide && {
  Authorization: `Bearer ${IMPERATIVE_value(TOKEN)}`,
}

export const axios: AxiosInstance = _axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    ...IMPERATIVE_bearerToken,
  },
  responseType: 'json',
})

import { captureException } from '@sentry/nextjs'
import { axios } from 'helpers/hooks/useAxios/axios'
import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import { RTProfileInfo, TProfile, TUpdateInfo } from './profile.types'

const updateInfo = async ({
  profileId,
  updatedProfileInfo,
  bearerToken,
}: TUpdateInfo): Promise<RTAxiosDefault> => {
  try {
    const { status } = await axios.put(
      ENDPOINTS.PROFILE(profileId),
      updatedProfileInfo,
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status }
  } catch (error) {
    const status = error.response.status
    captureException(error, {
      user: { id: profileId },
      extra: updatedProfileInfo,
    })

    return { status }
  }
}

const info = async (
  profileId: TProfile['id'],
  bearerToken: string
): Promise<RTProfileInfo> => {
  try {
    const { status, data } = await axios.get(ENDPOINTS.PROFILE(profileId), {
      headers: {
        Authorization: bearerToken,
      },
    })
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

export const profile_api = {
  info,
  updateInfo,
}

import { AxiosError } from 'axios'
import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'

export type TAuth = {
  password: string
  rememberMe: boolean
  username: string
}

export type RTAuth = RTAxiosDefault & {
  data?: {
    bearerToken: string
  }
}

export type TRequestResetPassword = {
  email: string
}

export type RTRequestResetPassword = {
  status: number
}

export type TResetToken = string

export type RTResetToken = boolean

export type TResetPassword = {
  newPassword: string
  token: string
}

export type RTResetPassword = {
  status: number
}

export type TCandidateToken = string

export type RTValidateToken = {
  status: number
  accessToken?: string
  refreshToken?: string
  error?: AxiosError<{ message?: string }>
}

export type RTGetLoginToken = {
  status: number
  accessToken?: string
  refreshToken?: string
}

export enum GRANT_TYPE {
  MS_ENTRA_ID = 'ms_entra_id',
  CANDIDATE_TOKEN = 'candidate_token',
  EMPLOYEE_TOKEN = 'employee_token',
  PASSWORD = 'password',
  PUBLIC_CANDIDATE_TOKEN = 'public_candidate_token',
  REFRESH_TOKEN = 'refresh_token',
  SHAREABLE_TOKEN = 'shareable_token',
}

export const ERROR_MESSAGES = {
  401: 'Your email or password is incorrect',
  402: 'Uh oh, your Thrive subscription is inactive! To reactivate it let us know ',
  409: `We can't find your live subscription. Contact us for more details `,
} as const

export type TRefreshTokenValidationData = {
  refreshToken: string
  redirectUrl: string
}

export const SSO_PROVIDER = {
  MICROSOFT: 'MICROSOFT',
} as const

export type TTSSOProvider = keyof typeof SSO_PROVIDER

export type TToastNotification = {
  id?: string
  onClose: VoidFunction
  type: TType
  message: string
}

export type TToastNotificationContainer = {
  type: TType
  animateOut: boolean
}

export enum TType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type TPageWithToastProps = {
  addToastNotification: (
    notification: Pick<TToastNotification, 'type' | 'message'>
  ) => void
}

import { StyledClose } from './close.styles'

export const Close = ({ color = 'white' }) => (
  <StyledClose
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <rect width="24" height="24" fill="transparent" />
    <path
      d="M18 6L6 18"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledClose>
)

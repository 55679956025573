export type CAMPAIGN_SENIORITY = 'seniority_campaignId'
export type CAMPAIGN_TARGET = 'target_campaignId'
export type CAMPAIGN_THRIVE_CANDIDATES_BANNER =
  'thrive_candidates_banner_campaignId'
export const EMPLOYER_NAVBAR_EXTENDED = 'employer_navbar_extended'
export const PREVIOUS_URL = 'previous_url'
export const STEPPER_CONFIG = 'stepper_config'
export const STEPPER_DATA = 'stepper_data'
export const STEPPER_RELATED_COOKIES = 'stepper_related_cookies'
export const PROJECT_EMPLOYEES_QUANTITY = 'project_employees_quantity'
export const USER = 'user'

export type TSessionStorageKey =
  | CAMPAIGN_SENIORITY
  | CAMPAIGN_TARGET
  | CAMPAIGN_THRIVE_CANDIDATES_BANNER
  | typeof EMPLOYER_NAVBAR_EXTENDED
  | typeof PREVIOUS_URL
  | typeof STEPPER_CONFIG
  | typeof STEPPER_DATA
  | typeof STEPPER_RELATED_COOKIES
  | typeof PROJECT_EMPLOYEES_QUANTITY
  | typeof USER

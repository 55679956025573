export const TOKEN = 'token'
export const CANDIDATE_TOKEN = 'candidate_token'
export const REFRESH_TOKEN = 'refresh_token'
export const EMPLOYEE_TOKEN = 'employee_token'
export type CAMPAIGN_PENDING_CANDIDATES = 'campaignId_pending_new_candidates'
export type PROJECT_PENDING_EMPLOYEES = 'projectId_pending_new_employee'

export type TLocalStorageKey =
  | typeof TOKEN
  | typeof REFRESH_TOKEN
  | typeof CANDIDATE_TOKEN
  | typeof EMPLOYEE_TOKEN
  | typeof EMPLOYEE_TOKEN
  | CAMPAIGN_PENDING_CANDIDATES
  | PROJECT_PENDING_EMPLOYEES

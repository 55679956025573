import { axios } from 'helpers/hooks/useAxios/axios'
import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import {
  ORGANISATION_PRODUCTS,
  RTGetCustomerPortalUrlData,
  PRICE_TYPE_MAP,
  RTAvailableProducts,
  RTOrganisationInfo,
  TGetCustomerPortalUrl,
  TStripePriceType,
  TAvailableProduct,
  TOrganisation,
  TTransformedAvailableProduct,
  TProductsByFrequency,
  TRequestCheckout,
  RTRequestCheckout,
  TRequestEndTrial,
} from './organisation.types'

const info = async (
  organisationId: TOrganisation['id'],
  bearerToken: string
): Promise<RTOrganisationInfo> => {
  try {
    const { status, data } = await axios.get(
      ENDPOINTS.ORGANIZATION.ROOT(organisationId),
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const requestProductAccess = async (
  organisationId: TOrganisation['id'],
  product: ORGANISATION_PRODUCTS,
  bearerToken: string
): Promise<RTAxiosDefault> => {
  try {
    const { status } = await axios.post(
      ENDPOINTS.ORGANIZATION.REQUEST_PRODUCT_ACCESS(organisationId),
      { product },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const getCustomerPortalUrl = async ({
  organisationId,
  token,
}: TGetCustomerPortalUrl): Promise<RTGetCustomerPortalUrlData> => {
  try {
    const response = await axios.get(
      ENDPOINTS.ORGANIZATION.CUSTOMER_PORTAL(organisationId),
      {
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      }
    )

    const { status, data } = response

    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status, data: null }
  }
}

const availableProducts = async (
  organisationId: TOrganisation['id'],
  bearerToken: string
): Promise<RTAvailableProducts> => {
  try {
    const { status, data } = await axios.get(
      ENDPOINTS.ORGANIZATION.AVAILABLE_PRODUCTS(organisationId),
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

// Transform stripe products into visible data
const transformProducts = (
  products: TAvailableProduct[],
  priceTypes: TStripePriceType[]
): TProductsByFrequency => {
  const result: TProductsByFrequency = Object.values(PRICE_TYPE_MAP).reduce(
    (acc, type) => {
      acc[type] = []
      return acc
    },
    {} as TProductsByFrequency
  )

  products.forEach((plan) => {
    priceTypes.forEach((priceType) => {
      if (plan[priceType as keyof TTransformedAvailableProduct] !== undefined) {
        const priceFrequency = PRICE_TYPE_MAP[priceType]

        result[priceFrequency].push({
          ...plan,
          priceId: plan[`${priceType}Id`],
          priceFrequency,
        })
      }
    })
  })

  // Sort products by tier key
  Object.keys(result).forEach((key) => {
    result[key].sort(
      (productA: TAvailableProduct, productB: TAvailableProduct) =>
        productA.tier - productB.tier
    )
  })

  return result
}

// Request stripe checkout for version A
const requestCheckout = async ({
  organisationId,
  requestBody,
  bearerToken,
}: TRequestCheckout): Promise<RTRequestCheckout> => {
  try {
    const { status, data } = await axios.post(
      ENDPOINTS.ORGANIZATION.CHECKOUT(organisationId),
      { ...requestBody },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

// Request stripe checkout for version A
const requestTrialCheckout = async ({
  organisationId,
  requestBody,
  bearerToken,
}: TRequestCheckout): Promise<RTRequestCheckout> => {
  try {
    const { status, data } = await axios.post(
      ENDPOINTS.ORGANIZATION.TRIAL_CHECKOUT(organisationId),
      { ...requestBody },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

// Request stripe checkout for version A
const endTrial = async ({
  organisationId,
  bearerToken,
}: TRequestEndTrial): Promise<RTAxiosDefault> => {
  try {
    const { status } = await axios.post(
      ENDPOINTS.ORGANIZATION.END_TRIAL(organisationId),
      {},
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

export const organisation_api = {
  availableProducts,
  endTrial,
  info,
  requestProductAccess,
  getCustomerPortalUrl,
  transformProducts,
  requestCheckout,
  requestTrialCheckout,
}

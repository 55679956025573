import { axios } from 'helpers/hooks/useAxios/axios'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import { RTExperimentsMe } from './experiments.types'

const getMe = async (bearerToken: string): Promise<RTExperimentsMe> => {
  try {
    const { status, data } = await axios.get(ENDPOINTS.EXPERIMENTS.ME, {
      headers: {
        Authorization: bearerToken,
      },
    })
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status, error }
  }
}

export const experiments_api = { getMe }

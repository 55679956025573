export type THeight = 'auto' | number | string
export type Twidth = 'auto' | number | string

export const size = (
  height: THeight = 'auto',
  width: Twidth = height
): string => {
  return `
    	height: ${height};
        width: ${width};
    `
}

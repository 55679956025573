import { useState, FC } from 'react'
import {
  ToastNotificationContainer,
  ToastIconContainer,
  CloseIconContainer,
  Icon,
} from './toastNotification.styles'
import { Close } from '@icons/close/close'
import { useEffect } from 'react'
import { Text } from '@atoms/text/text'
import { TType } from './toastNotification.types'
import { TToastNotification } from './toastNotification.types'
import { Warning } from '@icons/warning/warning'
export const ToastNotification: FC<TToastNotification> = ({
  type,
  message,
  onClose,
}) => {
  const [animateOut, setAnimateOut] = useState(false)

  const triggerClose = () => {
    setAnimateOut(true)
    setTimeout(() => {
      onClose()
    }, 2000)
  }

  useEffect(() => {
    const autoClose = setTimeout(() => {
      triggerClose()
    }, 10000)

    return () => clearTimeout(autoClose)
  }, [])

  return (
    <ToastNotificationContainer type={type} animateOut={animateOut}>
      <ToastIconContainer type={type}>
        {type === TType.SUCCESS ? (
          <Icon
            src="/images/svg/components/check_circle_white_filled.svg"
            alt="Success"
          />
        ) : (
          <Warning />
        )}
      </ToastIconContainer>
      <Text>{message}</Text>
      <CloseIconContainer onClick={triggerClose}>
        <Close color="black" />
      </CloseIconContainer>
    </ToastNotificationContainer>
  )
}

export const breakpoint = {
  desktop: {
    s: '1024px',
    m: '1440px',
    l: '2560px',
  },
  tablet: '768px',
  mobile: {
    s: '320px',
    m: '375px',
    l: '425px',
  },
}
